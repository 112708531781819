import React, { useState, useEffect, useRef, useMemo } from 'react';
import { hot } from 'react-hot-loader';
import { useWindowStore, windowApi } from 'services/WindowService';
import { ArtContainer, Artwork, ArtworkImage, ArtworkLabel as ArtworkLabel, UIContainer, Logo, Loader, LoaderCircle, ClickArea } from './styles';
import InfoButton from 'components/InfoButton';
import ArtworkOverlay from 'components/ArtworkOverlay';
import Onboarding from 'components/Onboarding';
import ScrollBooster from 'scrollbooster';
import LogoImg from 'assets/Images/Logo.png';
import Img1 from 'assets/Images/1-Valentin.png';
import Img2 from 'assets/Images/2-Alex.png';
import Img3 from 'assets/Images/3-Lujan.png';
//import Img3 from 'assets/Images/Head3.png';
//import Img4 from 'assets/Images/Head4.png';
import { everythingApi } from 'services/everythingService';

const viewportHeightPercentage = 82;
const imageHeight = 1400;
const startLeft = 200;

const Artworks = [
  {src: Img1, title:'Primal Scream', desc: "From the depth of digital infinity a forceful roar erupts, awaiting its resonance", date:'04.2022',
      clickArea: {left: 0, top: 14, width: 85, height: 74}, left: 0, width: 853, artistID: 'Valentin Scheiner'},
  {src: Img2, title:'Sacred Hands', date:'05.2022', desc: 'In your hands you hold the divine, the love, the pain, the infinite.', clickArea: {left: 20, top: 10, width: 75, height: 80}, left: 1074, width: 1074, artistID: 'Alexander Sketch'},
  {src: Img3, title:'Untitled', date:'06.2022', desc: '', clickArea: {left: 20, top: 10, width: 75, height: 85}, left: 2352, width: 1074, artistID: 'Lujan'},
  //{src: Img3, title:'No3', date:'09.2022', desc: 'No3 Description',clickArea: {left: 20, top: 16, width: 73, height: 55}, left: 1114, width: 674, artistID: 'Unknown'},
  //{src: Img4, title:'No4', date:'12.2099', desc: 'No4 Description',clickArea: {left: 20, top: 46, width: 73, height: 40}, left: 1450, width: 1440, artistID: 'Unknown'},
];

function Start() {
  const isMobile = useWindowStore(state => state.isMobile);
  const [focusedArtwork, setFocusedArtwork] = useState(1);
  const [imagesLoaded, setImagesLoaded] = useState(Array.from({length: Artworks.length}, () => false));

  useEffect(() => {
    windowApi.getState().init();
  }, []);

  const windowHeight = useWindowStore(state => state.height);
  const windowWidth = useWindowStore(state => state.width);
  const ArtContainerRef = useRef();
  const scaleMult = (windowHeight * (viewportHeightPercentage / 100)) / imageHeight;

  useEffect(()=>{
    if (ArtContainerRef.current) {
      const sb = new ScrollBooster({
        viewport: ArtContainerRef.current,
        scrollMode:'transform',
        direction: 'horizontal',
        bounceForce: 0.08,
        friction: 0.06,
        emulateScroll: true,
        onPointerDown: () => {
          everythingApi.getState().setIntroFinished(true);
        },
        onUpdate: (state) => {
          const scroll = state.position.x + windowWidth * 0.5;
          let dist = 99999999;
          let index = -1;
          for (let a = 0; a < Artworks.length; a++) {
            const artworkPos = (startLeft + Artworks[a].left + Artworks[a].width/2)*scaleMult;
            if(Math.abs(scroll-artworkPos) < dist) {
              dist = Math.abs(scroll-artworkPos); index = a;
            }
          }
          if (index !== focusedArtwork) {
            setFocusedArtwork(index);
          }
        },
      });
    }
  }, [ArtContainerRef.current]);

  const imageLoaded = (index) => {
    const newImagesLoaded = [...imagesLoaded];
    newImagesLoaded[index] = true;
    setImagesLoaded(newImagesLoaded);
  };

  const renderArtworks = () => {
    return Artworks.map((a, index) => {
      let cl = '';
      if (isMobile) {
        if(focusedArtwork===index) cl = 'focused';
      } else {
        cl = isMobile ? '':'isDesktop';
      }
      const ca = a.clickArea;
      return <Artwork left={(startLeft + a.left) * scaleMult} key={index} labelTop={a.labelTop} >
        <ArtworkImage src={a.src} index={index} onLoad={()=>{imageLoaded(index)}} className={imagesLoaded[index] && 'loaded'} />
        <Loader className={imagesLoaded[index] && 'loaded'}><LoaderCircle index={index} /></Loader>
        <ClickArea left={ca.left} top={ca.top} width={ca.width} height={ca.height} className={cl} onClick={()=>{everythingApi.getState().setShowArtwork({...a}, index)}}>
          <ArtworkLabel className='artist-label'>{a.title}<br /><span className='artist'>@{a.artistID}</span><div className='arrow-down'/></ArtworkLabel>
        </ClickArea>
      </Artwork>;
    });
  };

  return (
    <>
      <ArtContainer ref={ArtContainerRef} heightInPercent={viewportHeightPercentage}>
        <div style={{height: '100%'}}>
            {renderArtworks()}
        </div>
      </ArtContainer>
      <UIContainer>
        <Onboarding />
        <InfoButton />
        <Logo src={LogoImg} />
        <ArtworkOverlay />
      </UIContainer>
    </>
  );
}

export default hot(module)(Start);
