import { mediaMax } from 'common/breakpoints';
import styled, { css } from 'styled-components';
import { keyframes } from 'styled-components';

const aniDuration = 2;
const grabFrom = 20;

export const Curtain = styled.div`
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
`;

const grabAnimation = keyframes`
    0% { 
        opacity: 0;
    }
    ${grabFrom - 1}% {
        opacity: 0;
    }
    ${grabFrom}% {
        opacity: 1;
    }
    ${grabFrom + 20}% {
        transform: none;
    }
    ${grabFrom + 28}% {
        // transform: rotate(-4deg);
        transform: translateX(-15px) rotate(-2deg);
    }
    ${grabFrom + 42}% {
        // transform: rotate(4deg);
        transform: translateX(15px) rotate(2deg);
    }
    ${grabFrom + 50}% {
        transform: none;
    }
`;

const openAnimation = keyframes`
    0% { 
        opacity: 1;
    }
    ${grabFrom - 1}% {
        opacity: 1;
    }
    ${grabFrom}% {
        opacity: 0;
    }
`;

export const Container = styled.div`
    position: absolute;
    width: 300px;
    height: 200px;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    user-select: none;
    pointer-events: none;
    transition: opacity 0.5s ease-out;
    &.intro-finished{
        opacity: 0;
    }
`;

export const Gradient = styled.div`
    position: absolute;
    left: -50px;
    right: -50px;
    top: -100px;
    bottom: -100px;
    background: transparent;
    background: radial-gradient(circle, rgba(255,255,255,1) 0%, rgba(255,255,255,0) 60%);
`;

const fadeIn = keyframes`
    0% { opacity: 0; }
    100% { opacity: 1; }
`;

export const ImageContainer = styled.div`
    position: absolute;
    width: 75px;
    left: 50%;
    transform: translateX(-50%);
    opacity: 0;
    animation: ${fadeIn} 1s linear 2s forwards;
    img {
        position: absolute;
        &.open {
            animation: ${openAnimation} ${aniDuration}s linear infinite;
            opacity: 0;
        }
        &.grab {
            top: 15px;
            left: 4px;
            animation: ${grabAnimation} ${aniDuration}s linear infinite;
            transform-origin: 50% 300%;
        }
    }
`;

export const Text = styled.div`
    font-size: 16px;
    text-align: center;
    background-color: rgba(0, 0, 0, 50%);
    color: white;
    border-radius: 10px;
    width: fit-content;
    padding: 11px 30px 15px;
    top: 0;
    left: 50%;
    margin: 140px auto 0;
    opacity: 0;
    animation: ${fadeIn} 1s linear 2s forwards;
`;

export const Arrow = styled.img`
    position: absolute;
    top: 20px;
    &.mirrored {
        transform: scaleX(-1);
        right: 0;
    }
`;
