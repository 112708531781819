import { mediaMax } from 'common/breakpoints';
import styled, { css } from 'styled-components';
import { keyframes } from 'styled-components';

export const ArtContainer = styled.div`
    position: absolute;
    width: 100%;
    height: ${props => props.heightInPercent}%;
    top: 17%;
    user-select: none;
`;

const pulsate = keyframes`
  0% { transform: scale(0.6) rotate(0deg); opacity: 1; }
  50% { transform: scale(1.2) rotate(90deg); opacity: 0.6; border-radius: 25%;}
  100% { transform: scale(0.6) rotate(180deg); opacity: 1; }
`;

const fadeIn = keyframes`
  0% { opacity: 0; }
  100% { opacity: 1; }
`;

export const Loader = styled.div`
    position: absolute;
    width: 40px;
    height: 40px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    user-select: none;
    &.loaded {
      transform: translate(-50%, -50%) scale(0);
    }
    opacity: 0;
    transition: transform ease-out 0.5s;
    animation: ${fadeIn} 0.5s linear forwards 1s;
`;

export const LoaderCircle = styled.div`
    width: 100%;
    height: 100%;
    background: black;
    border-radius: 50%;
    animation: ${pulsate} 2s ease-in-out infinite ${props => props.index * 0.2}s;
`;

export const UIContainer = styled.div`
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    margin: 50px;
    pointer-events: none;
    >*{
      pointer-events: all;
    }
    ${mediaMax('small', css`
      margin: 30px 10px;
    `)}
`;

export const Artwork = styled.div`
    height: 100%;
    position: absolute;
    left: ${props => props.left}px;
    top: 0;
    pointer-events: none;
`;

export const ClickArea = styled.div`
  position: absolute;
  left: ${props => props.left}%;
  top: ${props => props.top}%;
  width: ${props => props.width}%;
  height: ${props => props.height}%;
  // background-color: rgba(255, 0, 0, 0.2);
  cursor: pointer;
  &.isDesktop:hover,&.focused {
    >.artist-label{
      transform: translate(-50%, -100%);
      opacity: 1;
    }
  }
  pointer-events: all;
  -webkit-tap-highlight-color: rgba(0,0,0,0);
`;

export const ArtworkImage = styled.img`
    height: 100%;
    transform: scale3d(0, 0, 0);
    opacity: 0;
    transition: all 0.5s cubic-bezier(.3,1.28,.82,1.01) ${props => (props.index+1)*0.25}s, filter 0.5s ease-out;
    &.loaded {
      opacity: 1;
      transform: scale3d(1, 1, 1);
    }
    // &:hover {
    //   filter: brightness(1.15);
    // }
`;

export const ArtworkLabel = styled.div`
  position: absolute;
  text-align: center;
  line-height: 22px;
  background-color: rgba(0, 0, 0, 0.6);
  color: white;
  border-radius: 5px;
  padding: 10px 25px;
  top: 0;
  width: fit-content;
  left: 50%;
  transform: translate(-50%, -80%);
  opacity: 0;
  transition: all 0.3s ease-out;
  pointer-events: none;
  font-weight: bold;
  font-size: 18px;
  .artist {
    font-size: 12px;
    font-weight: 400;
  }
  .arrow-down {
    position: absolute;
    bottom: -7px;
    left: 50%;
    transform: translate(-50%);
    width: 0; 
    height: 0; 
    border-left: 7px solid transparent;
    border-right: 7px solid transparent;
    border-top: 7px solid rgba(0, 0, 0, 0.6);
  }
`;

export const Logo = styled.img`
    position: absolute;
    left: -5px;
    top: -16px;
    height: 75px;
    opacity: 0.9;
`;
