import React, { useState } from 'react';
import { Button, InfoContainer, ArrowUp, FullScreen, Me } from './styles';

const InfoButton = () => {
    const [active, setActive] = useState(false);

    return (
        <>
            {active && <FullScreen onClick={()=>{setActive(false)}} />}
            <Button onClick={()=>{setActive(!active);}} className={active?'active':''}>About</Button>
            <InfoContainer className={active?'visible':''}>
                <ArrowUp width="50" height="30">
                    <polygon points="0,30 25,0 50,30" style={{fill:"#eaeee0"}} />
                    <line x1="0" y1="30" x2="25" y2="0" style={{stroke:"#5e5e5e", strokeWidth:2}} />
                    <line x1="25" y1="0" x2="50" y2="30" style={{stroke:"#5e5e5e", strokeWidth:2}} />
                </ArrowUp>
                The Art-Chain-Project connects artists from all over the world in one never-ending artwork. Different personalities, styles, themes and cultures meet in this digital space, where every artist takes the previous piece of art as a starting point.
                <br /><br />
                <b>Explore the chain by dragging it horizontally. Click or tap an artwork and get to know more about the creator.</b>
                <br /><br /><br />
                If you are an artist and want to be part of the project send us a mail to:<br/>
                <a href='mailto:link@artchainproject.com'>link@artchainproject.com</a>
                <Me>Project launched and maintained by <a href="https://www.instagram.com/valentinscheiner/" target="_blank">Valentin Scheiner</a></Me>
            </InfoContainer>
        </>
    );
  };
  
  export default InfoButton;