import React from 'react';
import ReactDOM from 'react-dom';
import Start from './components/Start';

// import './index.css';
import { GlobalStyle } from './styles';

const root = document.createElement('div');
root.id = 'application';
document.body.appendChild(root);

ReactDOM.render(
  <>
    <GlobalStyle />
    <Start />
  </>,
  root,
);

export default Start;
