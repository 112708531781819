import React, { useState } from 'react';
import { Curtain, Container, ImageContainer, Text, Arrow, Gradient } from './styles';
import CursorHand from 'assets/Images/CursorHand.png';
import CursorGrab from 'assets/Images/CursorGrab.png';
import ArrowLeft from 'assets/Images/IntroArrowLeft.png';
import { useEverythingStore } from 'services/everythingService';

const Onboarding = () => {
    const introFinished = useEverythingStore(state => state.introFinished);
    return (
        <>
            <Container className={introFinished && 'intro-finished'}>
                {/* <Gradient /> */}
                {/* <Arrow src={ArrowLeft} />
                <Arrow src={ArrowLeft} className='mirrored' /> */}
                <ImageContainer>
                    <img src={CursorHand} className='open' />
                    <img src={CursorGrab} className='grab' />
                </ImageContainer>
                <Text>Drag left &#38; right to explore</Text>
            </Container>
        </>
    );
  };
  
  export default Onboarding;