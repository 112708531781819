import styled, { createGlobalStyle, css } from 'styled-components';
import styledNormalize from 'styled-normalize';
import MontserratRegular from 'assets/Fonts/Montserrat-Regular.woff2';
import MontserratBold from 'assets/Fonts/Montserrat-SemiBold.woff2';
import { mediaMax } from 'common/breakpoints';

export const colors = {
  black: '#000000',
  green: {
    '25': 'rgba(0, 255, 105, 0.25)',
    '55': 'rgba(0, 255, 105, 0.55)',
    '100': 'rgba(0, 255, 105, 1)',
  },
};

export const GlobalStyle = createGlobalStyle`
  @font-face {
    font-family: 'Monti';
    font-weight: normal;
    src: url('${MontserratRegular}') format('woff2');
  }
  @font-face {
    font-family: 'Monti';
    font-weight: bold;
    src: url('${MontserratBold}') format('woff2');
  }
  ${styledNormalize}
  input, textarea, button, select, a {
    -webkit-tap-highlight-color: rgba(0,0,0,0);
  }
  html, body {
    width: 100%;
    height: 100%;
    overflow: hidden;
  }
  body {
    font-family: 'Monti', sans-serif, Helvetica;
    overscroll-behavior: contain;
    font-size: 12pt;
    ${mediaMax('small', css`
      font-size: 10pt;
    `)}
    background: #eaeee0;
  }
  #application {
    width: 100%;
    height: 100%;
    // -ms-overflow-style: none;  /* IE and Edge */
    // scrollbar-width: none;  /* Firefox */
    // &::-webkit-scrollbar {
    //   display: none;
    // }
  }
  h1 {
    font-size: 53px;
    font-weight: 900;
    margin: 10px 0;
    ${mediaMax('small', css`
      font-size: 32px;
    `)}
  }
  h2 {
    font-size: 30px;
    font-weight: 700;
    ${mediaMax('small', css`
      font-size: 18px;
    `)}
  }
`;

export const Button = styled.button`
  border: 0;
  padding: 0.5em 0.75em;
  border-radius: 0.35em;
  background-color: ${colors.green['55']};
  outline: none;
  cursor: pointer;
  transition: box-shadow 0.15s ease-in;

  &:active {
    background-color: ${colors.green['100']};
  }

  &:hover {
    box-shadow: 2px 2px 3px 0px rgba(0, 0, 0, 0.25);
  }
`;

export const easeOutStrong = 'cubic-bezier(.35,.96,.74,1)';
