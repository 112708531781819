import React, { useState } from 'react';
import { useEverythingStore, everythingApi } from 'services/everythingService';
import { Container, Curtain, Window, CloseButton, Date, Title, Description, Divider, DividerTitle, ArtistImage, ArtistInfoArea,
    ArtistName, ArtistDescription, SocialMediaLink, SocialMediaIcon, SocialMediaLabel, SocialMediaContainer, TitleInfo } from './styles';

const ArtworkOverlay = () => {
    const artwork = useEverythingStore(state => state.showArtwork);
    const artist = artwork && artwork.artist;

    const renderSocialMediaLinks = (social) => {
        return social.map((s, i) =>
            <SocialMediaLink key={i} onClick={()=>{window.open(s.link, '_blank')}}>
                <SocialMediaIcon src={s.icon} /><SocialMediaLabel>{s.label}</SocialMediaLabel>
            </SocialMediaLink>
        );
    };

    const renderArtistSection = () => {
        return (<>
            <Divider><DividerTitle>Artist:</DividerTitle></Divider>
            {artist.img && <ArtistImage src={artist.img} />}
            <ArtistInfoArea>
                <ArtistName>{artist.name}</ArtistName>
                <ArtistDescription>{artist.description}</ArtistDescription>
                {artist.social &&(
                    <SocialMediaContainer>
                        {renderSocialMediaLinks(artist.social)}
                    </SocialMediaContainer>
                )}
            </ArtistInfoArea>
        </>);
    };

    return (
        <>
            {artwork && <Curtain onClick={()=>{everythingApi.getState().setShowArtwork(undefined)}} />}
            {artwork && (<Container>
                <Window>
                    <Title>{artwork.title}</Title><TitleInfo><Date>{artwork.date}</Date></TitleInfo>
                    {artwork.desc && <Description>{artwork.desc}</Description>}
                    {artist && renderArtistSection()}
                    <CloseButton onClick={()=>{everythingApi.getState().setShowArtwork(undefined)}}>
                        <div className='one' />
                        <div className='two' />
                    </CloseButton>
                </Window>
            </Container>)}
        </>
    );
  };
  
  export default ArtworkOverlay;