import create from 'utilities/zustand/create';
import VS_image from 'assets/Images/VS.jpg';
import Alex_image from 'assets/Images/AlexS.jpg';
import Unknown_image from 'assets/Images/UnknownArtist.jpg';
import logoInstagram from 'assets/Images/logoInstagram.png';
import logoEmail from 'assets/Images/logoEmail.png';
import logoWebsite from 'assets/Images/logoWebsite.png';

const Artists = {
  'Valentin Scheiner': {name: 'Valentin Scheiner', description:'Travelling artist, illustrator, occasional coder. Creator of the Art Chain Project.',
  img: VS_image, social:[
  {icon: logoInstagram, label: 'Instagram', link: 'https://www.instagram.com/valentinscheiner/'},
  {icon: logoEmail, label: 'Email', link: 'mailto:valentinscheiner@gmail.com'},
  {icon: logoWebsite, label: 'Homepage', link: 'https://www.valentinscheiner.de'}]}
  ,
  'Lujan': {name: 'Julian Mayer', description:'',
  img: Unknown_image, social:[
  {icon: logoInstagram, label: 'Instagram', link: 'https://www.instagram.com/jul_maiii/'}]}
  ,
  'Alexander Sketch': {name: 'Alexander Sketch',
  img: Alex_image, social:[
  {icon: logoInstagram, label: 'Instagram', link: 'https://www.instagram.com/alexsketchtattoo/'}]}
  ,
  'Unknown': {name: 'Unknown Artist',
  img: Unknown_image}
};

export const [useEverythingStore, everythingApi] = create(module, set => ({
  introFinished: false,
  showArtwork: undefined,

  setShowArtwork: (showArtwork, index) => {
    if(showArtwork === undefined) {
      set({ showArtwork: undefined });
    } else {
      const newShowArtwork = {...showArtwork};
      newShowArtwork.artist = Artists[newShowArtwork.artistID];
      newShowArtwork.index = index;
      set({ showArtwork: newShowArtwork });
    }
  },
  setIntroFinished: introFinished => {
    set({ introFinished });
  },
}));
