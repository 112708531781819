import { mediaMax } from 'common/breakpoints';
import styled, { css } from 'styled-components';

export const Button = styled.div`
    position: absolute;
    padding: 0 50px;
    line-height: 49px;
    height: 50px;
    border: rgba(0, 0, 0, 0.8) solid 2px;
    border-radius: 25px;
    text-align: center;
    text-transform: uppercase;
    top: 0;
    right: 0;
    cursor: pointer;
    font-size: 20px;
    color: black;
    &:hover {
        text-decoration: underline;
    }
    &.active {
        box-shadow: 0 3px 6px rgba(0, 0, 0, 0.4) inset;
        border-color: rgba(0, 0, 0, 0.1);
        color: transparent;
        text-shadow: 0 1px 4px rgba(0, 0, 0, 0.6);
        text-decoration: none;
    }
    user-select: none;
    transition: all 0.1s linear;
`;

export const FullScreen = styled.div`
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background: black;
    opacity: 0.3;
`;

export const InfoContainer = styled.div`
    position: absolute;
    width: 100%;
    max-width: 800px;
    border: #5e5e5e solid 2px;
    border-radius: 25px;
    // background: rgba(234, 238, 224, 0.85);
    background: #EAEEE0;
    right: 0;
    top: 95px;
    font-size: 16px;
    padding: 30px 30px 60px;

    // backdrop-filter: blur(20px);
    box-sizing: border-box;
    transition: all 0.1s ease-out;
    opacity: 0;
    pointer-events: none;
    transform: translateY(-10px);
    &.visible {
        opacity: 1;
        pointer-events: all;
        transform: none;
    }
    >a{
        color: black;
        font-weight: bold;
    }
    ${mediaMax('small', css`
        padding: 30px 20px 55px;
        font-size: 14px;
    `)}
    // box-shadow: 3px 3px rgb(0 0 0 / 60%);
`;

export const Me = styled.div`
    position: absolute;
    bottom: 20px;
    right: 30px;
    font-size: 8pt;
    color: #666;
    a {
      color: #666;
      font-weight: bold;
    }
    z-index: 1;
`;

export const ArrowUp = styled.svg`
    position: absolute;
    right: 58px;
    top: -30px;
`;