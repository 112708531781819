import { mediaMax } from 'common/breakpoints';
import styled, { css } from 'styled-components';
import { keyframes } from 'styled-components';

export const Container = styled.div`
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    pointer-events: none;
    >* {
        pointer-events: all;
    }
`;

export const Curtain = styled.div`
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background: black;
    opacity: 0.3;
`;

export const TitleInfo = styled.div`
    float: left;
    font-size: 20px;
    color: #999f8b;
    font-weight: bold;
    ${mediaMax('small', css`
        font-size: 12px;
    `)}
    margin-bottom: 10px;
`;

export const Date = styled.span`
    background-color: #adb3a1;
    color: #EAEEE0;
    padding: 0 5px;
    font-weight: normal;
    ${mediaMax('small', css`
        font-size: 12px;
    `)}
`;

export const Title = styled.h1`
    float: left;
    font-weight: normal;
    font-size: 72px;
    margin: 0 7px 8px 0;
    ${mediaMax('small', css`
        font-size: 40px;
        margin: 0 4px 5px 0;
    `)}
`;

export const Description = styled.p`
    font-size: 25px;
    ${mediaMax('small', css`
        font-size: 15px;
    `)}
    margin: 15px 0 30px;
    clear: both;
`;

export const Divider = styled.div`
    width: 100%;
    height: 3px;
    background: #c7cdba;
    margin: 30px 0;
    ${mediaMax('small', css`
        margin-top: 20px;
    `)}
    clear: both;
`;

export const DividerTitle = styled.div`
    position: absolute;
    padding: 2px 20px;
    left: 50%;
    transform: translate(-50%, -45%);
    font-weight: bold;
    text-transform: uppercase;
    font-size: 16px;
    background: #EAEEE0;
    color: #999f8b;
`;

export const ArtistImage = styled.img`
    float: left;
    width: 190px;
    height: 190px;
    overflow: hidden;
    border-radius: 50%;
    border: 2px solid black;
    margin-right: 30px;
    ${mediaMax('small', css`
        width: 90px;
        height: 90px;
        margin-right: 20px;
    `)}
`;

export const ArtistInfoArea = styled.div`
    float: left;
    width: calc(100% - 230px);
    ${mediaMax('small', css`
        width: calc(100% - 115px);
    `)}
`;

export const ArtistName = styled.h2`
    margin-top: 20px;
    ${mediaMax('small', css`
        margin-top: 0;
    `)}
`;

export const ArtistDescription = styled.p`
    font-size: 20px;
    ${mediaMax('small', css`
        font-size: 12px;
    `)}
`;

export const SocialMediaContainer = styled.div`
    margin-top: 30px;
    ${mediaMax('small', css`
        margin-top: 20px;
    `)}
`;

export const SocialMediaLink = styled.div`
    float: left;
    margin: 0 10px 8px 0;
    cursor: pointer;
    border-radius: 30px;
    border: 1px solid black;
    // backgrounsd: #c8cebb;
    padding: 8px 18px;
    ${mediaMax('small', css`
        margin: 0 7px 7px 0;
    `)}
    // box-shadow: 0px 3px 7px 0px rgba(0,0,0,0.48);
`;

export const SocialMediaIcon = styled.img`
    float: left;
    margin-right: 8px;
    width: 20px;
    height: 20px;
    ${mediaMax('small', css`
        margin-right: 5px;
        width: 12px;
        height: 12px;
    `)}
`;

export const SocialMediaLabel = styled.div`
    font-size: 16px;
    font-weight: bold;
    // text-decoration: underline;
    float: left;
    ${mediaMax('small', css`
        font-size: 10px;
    `)}
`;

const popIn = keyframes`
    0% { opacity: 0; transform: translate(-50%, -50%) scale3d(0.8, 0.8, 0.8)}
    100% {opacity: 1; transform: translate(-50%, -50%)}
`;

export const Window = styled.div`
    position: absolute;
    left: 50%;
    top: 50%;
    padding: 50px;
    background: #EAEEE0;
    border: 2px solid #333;
    border-radius: 29px;
    width: fit-content;
    max-width: 1100px;
    box-sizing: border-box;
    animation: ${popIn} 0.5s cubic-bezier(.19,1.36,.42,.99) forwards;
    ${mediaMax('small', css`
        padding: 30px 20px;
    `)}
`;

export const CloseButton = styled.div`
    position: absolute;
    top: 25px;
    right: 25px;
    width: 35px;
    height: 35px;
    cursor: pointer;
    >div {
        position: absolute;
        width: 2px;
        height: 100%;
        background: black;
        &.one { transform: translateX(16px) rotate(-45deg); }
        &.two { transform: translateX(16px) rotate(45deg); }
    }
    ${mediaMax('small', css`
        top: 15px;
        right: 15px;
        width: 25px;
        height: 25px;
        >div {
            &.one { transform: translateX(11px) rotate(-45deg); }
            &.two { transform: translateX(11px) rotate(45deg); }
        }
    `)}
`;
